<template>
  <div class="container">
    <div class="head">
      <router-link to="Product">
        <van-icon name="cross" size="21" color="#fff" />
      </router-link>
      <div class="title">{{ $t('login').title }}</div>
      <span @click="showLanguage = true">{{ $t('login').lang }}</span>
    </div>
    <div class="logo">
      <!-- {{ showCompanyName() }} -->
      <img :src="basicConfig.SquareLogo" alt="" style="height: 200px;width: 200px;" />
    </div>
    <div class="form">
      <div class="item">
        <p>{{ $t('login').Account }}：</p>
        <input type="text" v-model="username" />
      </div>

      <div class="item">
        <p>{{ $t('login').Password }}：</p>
        <!-- <input type="password" v-model="password"  @keyup.enter="handleLogin" /> -->
        <!-- custom-style="background-color: #ffffff; color: #ffffff; border-radius: 4px;" -->
        <van-field
          v-model="password"
          right-icon="eye"
          :type="!passwordStatus?'password':'text'"
          @click-right-icon="passwordStatus=!passwordStatus"
          @keyup.enter="handleLogin"
        />
      </div>
    </div>
    <div class="btn" @click="handleLogin">
      {{ $t('login').btn }}
    </div>
    <div class="forget" @click="handleHelp">
      {{ $t('login').ForgotPassword }}
    </div>
    <div class="reg">
      {{ $t('login').NoAccount }}
      <router-link tag="span" to="Register">{{
        $t('login').Registration
      }}</router-link>
    </div>
    <!-- <div v-if="basicConfig.IsWeChatLogin">
      <van-divider :style="{ color: '#999999', borderColor: '#5b5b5b' }">{{
        $t('login').OtherLogin
      }}</van-divider>
      <img src="@/assets/wx.png" alt="" @click="handleWXLogin" />
    </div> -->
    <div class="agreement" v-if="basicConfig.IsAgreement">
      <van-checkbox icon-size="14px" v-model="checked"></van-checkbox>
      <span>
        {{ $t('login').ageent }}
        <i @click="$router.push({ name: 'Agreement' })"
          >《{{ $t('login').ageentTitle }}》</i
        >
      </span>
    </div>
    <div class="footer">
      <div class="flex-c">
        <div class="text-foot">{{ $t('login').renzheng }}</div>
      </div>
      <div class="flex-c">
        <img class="img-foot" src="@/assets/newHome/beian.png" alt="" />
        <div class="text-foot">{{ $t('login').beian }}</div>
      </div>
    </div>
    <Language :showLanguage="showLanguage" @closePopup="showLanguage = false" />
  </div>
</template>

<script>
import Language from '@/components/language'
import userApi from '@/api/user'
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/event-bus'
export default {
  components: {
    Language,
  },
  data() {
    return {
      checked: true,
      showLanguage: false,
      username: null,
      password: null,
      passwordStatus:false,
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  methods: {
    showCompanyName() {
      let basicConfig = this.basicConfig
      let lang = this.$store.getters.lang
      if (lang === 'zh') {
        return basicConfig.CompanyName
      } else if (lang === 'en') {
        return basicConfig.CompanyNameEnglish
      } else if (lang === 'ft') {
        return basicConfig.CompanyNameTraditional
      } else {
        return basicConfig.CompanyNameJapanese
      }
    },
    handleWXLogin() {
      location.href = this.basicConfig.AuthoriseLoginUrl
    },
    loginSuccess(token) {
      Token.set(token)
      this.$toast.success(this.$t('login').success)
      EventBus.$emit('Login')
      setTimeout(() => {
        this.$router.push({ name: 'Product' })
      }, 1000)
    },
    async handleLogin() {
      if (this.username && this.password) {
        if (this.basicConfig.IsAgreement && !this.checked) {
          this.$toast(this.$t('login').ageentFail)
          return
        }
        let res = await userApi.getRefreshToken({
          username: this.username,
          password: this.password,
        })
        res = await userApi.login(res.Token)
        this.loginSuccess(res.Token)
      } else {
        this.$toast(this.$t('login').fail)
      }
    },
    handleHelp() {
      window.location.assign(this.basicConfig.ServiceUrl)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  height 100vh
  color #e9bd41
  font-size 14px
  background: #1f1f1f;
  text-align center
  .van-field 
    border 1Px solid #333a43
    background #2a2929
    margin 25px auto
    height 40px
    >>> .van-field__label
      color #ebe5cd
    >>> .van-field__control
      color #fff
.head
    color #fff
    width 100%
    text-align center
    height 50px
    display flex
    justify-content space-between
    align-items center
    padding 0 15px
    .title
      font-size 16px
    span
      padding 0 10px
      height 30px
      line-height 30px
      display inline-block
      border 1px solid #999
      border-radius 10px
      color #999
  .logo
    font-weight bold
    width 50%
    margin-top 15%;
    transform translateX(50%);

  .form
    text-align left
    margin 30px auto
    width 300px
    color #fff
    .item
      margin 25px auto
      p
        margin 7.5px 0
        line-height 19px
      input
        box-sizing border-box
        background: #2a2929;
        height 40px
        border 1Px solid #333a43
        width 300px !important
        padding 0 10px
  .btn
    height 44px
    border 1Px solid #e9bd41
    line-height 44px
    width 300px
    margin 40px auto 20px
  .reg
    color #ffffff
    margin-top 20px
    span
      color #e9bd41
  img
    margin-top 20px
    width 30px
    height 30px
.agreement
  position fixed
  left 0
  bottom 20px
  width 100vw
  flex-center()
  color #ffffff
  font-size 12px
  span
    margin-left 5px
    i
      font-style normal
      text-decoration underline

.footer
  margin-top 50px
  left 0
  bottom 0
  width 100%
  //background #2a2929
  padding 15px 0
  .flex-c
   display flex
   align-items center
   justify-content center  
   height 30px
   .img-foot
    width 18px
    height 18px
    margin-right 5px
    margin-top 0;
  .text-foot
    color #fff
    font-size 12px
  
</style>
